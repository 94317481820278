








































































import {Component, Prop, Watch} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {Action} from 'vuex-class';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {AssociateAccount} from '@/domain/model/types';
import {storage} from '@/plugins/firebase.init';
import {twoChars} from '@/utils/helpers';
import {profileStore} from '@/store/modules/profile';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import {ref, uploadBytes} from 'firebase/storage';


@Component({
  name: 'dialog-edit-profile',
  components: {AvatarWithStatus, BaseDialog}
})
export default class DialogEditProfile extends mixins(Notifications) {
  @Prop() value!: boolean;
  @Prop() user?: any;

  srcAssociate?: AssociateAccount | null = null;

  associateImageFile: File | null = null;
  associateImageUrl: string | null = null;
  phoneNumber: string = ''
  position: string = ''
  disable: boolean = true
  saving: boolean = false;

  @Action savePrivateInfoChanges;
  @Action uploadProfilePhoto;

  get abbreviation() {
    return twoChars(this.associate!.fullName);
  }

  get associate() {
    return this.user || profileStore.t2bUser;
  }

  get disableButton() {
    return this.saving || (!this.privateInfoChanged && !this.avatarPhotoChanged) || this.disable;
  }

  get avatar() {
    return this.associateImageUrl || (this.associate!.photoUrl && this.associate!.photoUrl.thumbnail);
  }

  get privateInfoChanged(): boolean {
    const phoneChanged = this.phoneNumber?.replace(/\D/g, '') !== this.srcAssociate?.phoneNumber?.replace(/\D/g, '')
    const positionChanged = this.position !== this.srcAssociate?.position
    return !!this.srcAssociate && !!this.srcAssociate.id && (phoneChanged || positionChanged);
  }

  get avatarPhotoChanged() {
    return !!this.associateImageFile && !!this.associateImageUrl;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  @Watch('phoneNumber')
  onChangePhone() {
    const formPrivateInfo: any = this.$refs.formPrivateInfo
    this.disable = !formPrivateInfo.validate()
  }

  @Watch('position')
  onChangePosition() {
    const formPrivateInfo: any = this.$refs.formPrivateInfo
    this.disable = !formPrivateInfo.validate()
  }

  async onSaveChanges() {
    this.saving = true;
    try {
      const formPrivateInfo: any = this.$refs.formPrivateInfo;
      if (this.privateInfoChanged && formPrivateInfo.validate()) {
        const {position, phoneNumber} = this
        this.associate.position = position
        this.associate.phoneNumber = phoneNumber
        const ok = await profileStore.updateProfile(this.associate)
        if (!ok) {
          this.saving = false;
        }
      }
      if (this.avatarPhotoChanged) {
        // Upload profile photo
        const uploadData = {
          associateId: this.associate!.id,
          imageFile: this.associateImageFile
        };
        const imagesRef = ref(storage, `users/${uploadData.associateId}/profilePhoto/${uploadData.imageFile!.name}`);
        const result = await uploadBytes(imagesRef, uploadData.imageFile!);
        console.log(`Profile photo has been uploaded - ${result.ref}`);
      }
      this.show = false;
      this.$emit('on-changes-saved');
    } catch (err) {
      this.$emit('on-error');
      console.log(`error: ${err}`);
    }
    this.saving = false;
  }

  onAssociateImageFileSelected(event) {
    console.log('DIALOG_EDIT_PROFILE -> onAssociateImageFileSelected');
    this.associateImageFile = event.target.files[0];
    if (this.associateImageFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.associateImageUrl = e.target.result || null;
      };
      reader.readAsDataURL(this.associateImageFile);
    }
  }

  onCloseDialog() {
    if (this.privateInfoChanged) {
      Object.assign(this.associate, this.srcAssociate)
    }
  }

  created() {
    this.srcAssociate = Object.assign({}, this.associate)
    this.position = this.associate?.position
    this.phoneNumber = this.associate?.phoneNumber
  }

  updated() {
    console.log('DIALOG_EDIT_PROFILE -> updated');
  }

  beforeDestroy() {
    console.log('DIALOG_EDIT_PROFILE -> beforeDestroy');
    //todo: reset values if not saved
  }
}
